<template>
  <div class="w-full">
    <div
      class="model items-center w-full pl-4 pr-4 cursor-pointer"
      @click="openUpload()"
    >
      <div class="text-c40 text-90 text-center w-full">+</div>
      <div class="w-full place text-center">
        <div v-if="placeholder !== null">
          {{ placeholder.substr(0, 25) }}<span v-if="placeholder.length > 25">...</span>
        </div>

        <div class="text-90 text-c12" v-if="placeholder === null">Facture reçu du fournisseur</div>
      </div>
    </div>
  </div>
</template>

<script>
import img from '../../../assets/icons/img.svg'

export default {
  name: 'Index',
  components: {
  },

  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        img
      },
      activeDetail: false,
      selectedItem: null,
      file: null,
      url: null,
      scale: 100,
      quality: 30,
      originalSize: true,
      compressed: null,
      placeholder: null
    }
  },

  computed: {

  },

  mounted () {
    if (this.value !== null) {
      this.placeholder = this.value
    }
  },

  methods: {
    openUpload () {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        this.placeholder = e.target.files[0].name
        this.$emit('info', this.file)
      }
      input.click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.model {
  background: rgba(242, 245, 249, 0.5);
  border: 1px dashed #BBBBBB;
  box-sizing: border-box;
  border-radius: 10px;
  height: 98px;
}
.imag{
  width: 69px;
  height: 69px;
  background: #EFEFEF;
  border-radius: 12px;
}
.compressor{
  display: none;
}

img{
  object-fit: cover;
  border-radius: 12px;
}
.place{
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.resp{
  display: none;
}
.button{
  background-color: #EFEFEF;
  height: 30px;
  border-radius: 3px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #5138EE;
}
.model:focus-within {
  border: 1px solid $focus-color;
}
.model:hover {
  border: 1px solid $hover-color;
}
@media only screen and (max-width: 700px) {
 .place{
   display: none;
 }
  .model{
    justify-content: center;
  }
  .resp{
    display: block;
    font-size: 14px;
  }
}
</style>
