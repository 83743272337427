<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 md:pb-4 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c24 font-c7 w-4/5"
            >
              Facture interne
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12 text-left">

            <div class="text-90">
              Nombre de verres :  <span class="text-red">{{ donnes.length }}</span>
            </div>

            <div class="text-90 mt-3">
              Montant total :  <span class="text-red">{{ calcule().toLocaleString() }} FCFA</span>
            </div>

            <div class="text-60 text-c12 text-left bloc p-4 flex mt-3">
              <div class="w-1/2">
                Solde du fournisseur
              </div>

              <div class="w-1/2 text-right text-black">
                <span v-if="supplier.balance"> {{ supplier.balance.toLocaleString() }} FR</span>

              </div>
            </div>

            <div class="mt-6">
              <div class="text-left text-c12">
                Référence facture fournisseur
              </div>

              <div class="mt-2">
                <inputo
                    placeholder="Ex: LAB3909O3"
                    @info="retourReference"
                />
              </div>
            </div>


            <div class="mt-6">
              <div class="text-left text-c12">
                Pièce jointe
              </div>

              <div class="mt-2">
                <upload
                    type="number"
                    @info="retourFile"
                />
              </div>
            </div>

            <div>
              <div class="mt-6">
                <div class="text-left text-c12">
                  Observation
                </div>

                <div class="mt-2">
                  <textar
                      radius="5px"
                      @info="retourNote"
                  />
                </div>
              </div>
            </div>


            <div
                v-if="error !== null"
                class="mt-6 text-red text-c13 text-center"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex p-6 pt-0 bg-white">
            <div class="w-1/2 mr-6">
              <buton
                  label="Annuler"
                  radius="5px"
                  color="black"
                  background="#DDDDDD"
                  height="55px"
                  @oga="fermer"
              />
            </div>

            <div class="w-1/2">
              <buton
                  label="Enregistrer"
                  radius="5px"
                  height="55px"
                  :charge="charge"
                  @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'
import upload from '../helper/form/upload'
import textar from '../helper/form/textarea'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    inputo,
    upload,
    textar
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Array,
      default: null
    },
    supplier: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      date: '',
      error: null,
      charge: false,
      reference: '',
      file: null,
      note: '',
      docUrl: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    calcule () {
      let amount = 0
      for (let item in this.donnes) {
        if (this.donnes[item].amount) {
          amount = amount + this.donnes[item].amount
        }
      }
      return amount
    },

    retourReference (answer) {
      this.reference = answer
    },

    retourFile (answer) {
      this.error = null
      this.error = null
      this.file = answer
      this.saveFile()
    },

    retourNote (answer) {
      this.note = answer
    },

    continuer() {
      this.error = null
      if (this.reference !== '' && this.file !== null) {
        this.charge = true
        const tab = []
        for (let item in this.donnes) {
          let obj = {}
          obj.id = this.donnes[item].id
          if (this.donnes[item].state === 'PENDING' || this.donnes[item].state === 'VALID') {
            obj.state = 'CONFORM'
          } else {
            obj.state = 'NOT_CONFORM'
          }
          tab.push(obj)
        }

        const body = {
          reference: this.reference,
          observation: this.note,
          url: this.docUrl,
          articles: tab,
          meta: {}
        }
        this.finalSave(body)
      } else {
        this.error = 'Tout les champs sont requis'
      }
    },

    async saveFile () {
      let data = new FormData()
      data.append('files', this.file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            this.docUrl = response[0].url
          })
          .catch(error => {
            this.error = 'Format de fichier incompatible. PDF, JPG, PNG requis'
            console.log(error)
          })
    },

     finalSave (body) {
       http.post(apiroutes.baseURL + apiroutes.createFacture, body)
          .then(response => {
            console.log('success')
            console.log(response)
            this.charge = false
            this.$emit('oga', true)
          })
          .catch(error => {
            console.log(error)
            this.charge = false
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}

.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: auto;
  margin: auto;
  border-radius: 12px;
}
.bloc{
  min-height: 40px;
  background: rgba(251, 167, 5, 0.2);
  border-radius: 5px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
