<template>
  <div>
    <factureInterne :activation="activeFacture" v-if="activeFacture" :supplier="supplier" :donnes="finalData" @oga="retourFacture"/>
    <devisFacture :activation="activeDevis" v-if="activeDevis" :donnes="dataSelect" @oga="retourDevis"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            @click="goBack"
            original
          />
          <div class="ml-4 w-4/5">
            Contrôle factures
          </div>
        </div>

        <div class="w-1/2">
          <div class="flex justify-end w-full">
            <bouton v-if="donnes.length === 0" label="+ Facture interne" height="54px" background="#DDDDDD" color="#606060" radius="5px" class="w-1/3"/>
            <bouton v-if="donnes.length > 0" label="+ Facture interne" height="54px" color="white" radius="5px" class="w-1/3" @click="activeDevis = true"/>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
          <div class="mt-4">
            <div class="all">
              <div class="w-full flex items-center text-90 text-c16 font-c5 text-left mb-6">
                <div class="w-10">
                  <radio @click="activeAll"/>
                </div>
                <div class="w-1/6 ml-4">
                  INFOS COMMERCIALES
                </div>
                <div class="w-1/5 ml-4">
                  CLIENT
                </div>
                <div class="w-20 ml-4">
                  OEIL
                </div>
                <div class="w-1/6 ml-4 text-left">
                 NOM FOURNISSEUR
                </div>
                <div class="w-1/6 ml-4">
                  NOM DU VERRE
                </div>

                <div class="w-1/6 ml-4">
                  PRIX D'ACHAT
                </div>
              </div>

              <div class="diviser" />

              <div
                  v-if="charge && donnes.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="70"
                />
              </div>

              <div v-if="donnes.length === 0" class="text-center text-90 mt-16 text-c18 mb-16">
                Aucune donnée pour le moment
              </div>

              <div
                  v-for="(item, index) in donnes"
                  :key="index"
                  class="mt-5"
              >
                <div
                    v-if="index > 0"
                    class="diviser mt-5"
                />
                <div class="flex text-left mt-6">
                  <div class="w-10">
                    <radio :all="allSelect" @click="selected(item)"/>
                  </div>
                  <div class="w-1/6 ml-4">
                    <div>{{ item.product.glassType}} - {{ item.product.ref}}</div>
                    <div>{{ item.product.meta.generation}}</div>
                  </div>

                  <div class="w-1/5 ml-4">
                    {{ item.estimate.client.surname }} {{ item.estimate.client.name }}
                  </div>

                  <div class="w-20 ml-4">
                    <span v-if="item.meta !== undefined && item.meta !== null">{{ item.meta.oeil }}</span>
                  </div>

                  <div class="w-1/6 ml-4 text-left">
                    {{ item.product.meta.supplier.brand }} {{ item.product.meta.supplier.indice }}

<!--                    traitement de masse -->
                    <span v-if="item.treatment !== undefined && item.treatment !== null">
                      <span v-for="(treat, id) in item.treatment" :key="id">
                        <span v-if="treat.product.meta">
                          <span v-if="treat.product.meta.intituleFournisseur !== undefined">
                             {{treat.product.meta.intituleFournisseur}}
                          </span>
                        </span>
                      </span>
                    </span>

                    <span v-if="item.meta"> {{ item.meta.treatmentData.color }}</span>
                  </div>

                  <div class="w-1/6 ml-4">
                    {{item.product.name}}
                  </div>

                  <div class="w-1/6 ml-4">
                    <span v-if="item.product.price">{{item.product.price.toLocaleString()}}</span>
                    <span v-if="!item.product.price"> - </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import search from '../../../assets/icons/search.svg'
import calendar from '../../../assets/icons/calendar.svg'
import filter from '../../../assets/icons/filter.svg'
import bouton from '../../helper/add/button'
import radio from '../../helper/form/radio'
import devisFacture from '../../popup/devisFacture'
import factureInterne from '../../popup/factureInterne'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"

export default {
  name: "Index",

  components: {
    // multiselect,
    bouton,
    radio,
    devisFacture,
    factureInterne
  },

  props: {
    donnes: {
      type: Object,
      default: null
    },
    supplier: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      filtre: ['En attente de validation', 'Valider', 'Invalider'],
      devis: [
        {
          id: '123456'
        },
        {
          id: '12394'
        },
        {
          id: '12s83456'
        },
      ],
      charge: false,
      activeDevis: false,
      allSelect: false,
      dataSelect: [],
      activeFacture: false,
      finalData: [],
      fournisseur: {}
    }
  },

  created () {
    this.fournisseur = this.supplier
    this.getSupplier()
  },

  methods: {
    retourDevis (answer) {
      if (answer !== false) {
        this.finalData = answer
        this.activeFacture = true
      }
      this.activeDevis = false
    },

    getSupplier () {
      http.get(apiroutes.baseURL + apiroutes.getSupplier +'?id=' + this.supplier.id)
          .then(response => {
            this.fournisseur.balance = response.balance
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            console.log(error)
            this.charge = false
          })
    },

    retourFacture (answer) {
      if (answer) {
        this.goBack()
      }
      this.activeFacture = false
    },

    goBack () {
      this.$emit('oga', false)
    },

    activeAll () {
      this.allSelect =! this.allSelect
      if (this.allSelect) {
        this.dataSelect = this.donnes
      } else {
        this.dataSelect = []
      }
    },

    selected (item) {
      let exist = null
      for (let i in this.dataSelect) {
        if (this.dataSelect[i].id === item.id) {
          exist = i
          this.dataSelect.splice(i, 1)
        }
      }

      if (exist === null) {
        this.dataSelect.push(item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
