<template>
  <div class="item cursor-pointer pl-10 pr-10 pt-6 pb-6">
    <div class="w-full flex text-c14 font-c4 text-left items-centerr">
      <div>
        <div class="text-c24">{{ nom }}</div>
        <div class="text-c48 text-yell mt-6">{{ total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    nom: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
