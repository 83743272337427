<template>
  <div class="p-10 pr-16 all">
    <div v-if="!activeDetail">
      <div>
        <div
          class="flex items-center"
        >
          <div class="w-full flex text-left text-c36 items-center font-c6">
  <!--          <icon-->
  <!--            :data="icons.back"-->
  <!--            height="70"-->
  <!--            width="70"-->
  <!--            class="mr-2 cursor-pointer"-->
  <!--            original-->
  <!--          />-->
            <div class="ml-4 w-3/5">
              Fournisseurs métiers
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="charge && supplierData.length === 0"
          class="flex justify-center mt-10"
      >
        <easy-spinner
            type="dots"
            size="70"
        />
      </div>

      <div v-if="supplierData.length === 0" class="text-90 text-center mt-12 text-c18">Aucune donnée pour le moment</div>

      <div class="mt-10 flex flex-wrap">
        <div class="w-1/3 pr-10 mb-10" v-for="(item, index) in supplierData" :key="index" @click="selected(item)">
          <bloc class="w-full" :nom="item.name" :total="item.glass.length"/>
        </div>
      </div>
    </div>

    <div v-if="activeDetail">
      <detail @oga="activeDetail = false" :donnes="selectedItem"/>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import search from '../../assets/icons/search.svg'
import bloc from '../../component/cdevs/controle/bloc'
import detail from '../../component/cdevs/controle/details'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: "Index",

  components: {
    bloc,
    detail
  },

  data () {
    return {
      icons: {
        back,
        search,
      },
      activeDetail: false,
      supplierData: [],
      charge: true,
      selectedItem: null
    }
  },

  created () {
    this.allData()
  },

  methods: {
    selected (item) {
      this.selectedItem = item
      this.activeDetail = true
    },

    allData () {
      http.get(apiroutes.baseURL + apiroutes.allSupplier)
          .then(response => {
            console.log(response)
            this.supplierData = response
            this.charge = false
          })
          .catch(error => {
            if (error.data.statusCode === 401){
              this.$router.go('/controle')
            }
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
