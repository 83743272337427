<template>
  <div>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c36 items-center font-c6">
          <icon
              :data="icons.back"
              height="70"
              width="70"
              class="mr-2 cursor-pointer"
              @click="$emit('oga', false)"
              original
          />
          <div class="">
            Reception de verres
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
          <div class="mt-4">
            <div class="all">
              <div class="w-full flex items-center text-90 text-c16 font-c5 text-left mb-6">
                <div class="w-1/4 ml-4">
                  FACTURE INTERNE
                </div>
                <div class="w-1/4 ml-4 text-center">
                  NOMBRE DE VERRES
                </div>
                <div class="w-1/4 ml-4 text-center">
                  VERRES REÇUS
                </div>
                <div class="w-1/4 ml-4 text-left">
                  DATE
                </div>
              </div>

              <div class="diviser" />

              <div
                  v-if="charge && donnes.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="70"
                />
              </div>

              <div
                  v-for="(item, index) in donnes"
                  :key="index"
                  class="mt-5 cursor-pointer"
                  @click="selected(item)"
              >
                <div
                    v-if="index > 0"
                    class="diviser mt-5"
                />
                <div class="flex text-left mt-6">
                  <div class="w-1/4 ml-4">
                   {{item.code}}
                  </div>

                  <div class="w-1/4 ml-4 text-center">
                    {{item.articles.length}}
                  </div>

                  <div class="w-1/4 ml-4 text-center">
                   {{nomberTrack(item.articles)}}
                  </div>

                  <div class="w-1/4 ml-4">
                   {{conversion(item.created_at)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'

export default {
  name: "Index",

  components: {
  },

  props: {
    donne: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back
      },
      donnes: [],
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      devis: [
        {
          id: '123456'
        },
        {
          id: '12394'
        },
        {
          id: '12s83456'
        },
      ],
      charge: false,
    }
  },

  created () {
   this.donnes = this.donne
    console.log(this.donnes)
  },

  methods: {

    conversion (item) {
      return new Date(item).toLocaleDateString()
    },

    nomberTrack (data) {
      let lent = 0
      for (let item in data) {
        if (data[item].isReceive === true) {
          lent = lent + 1
        }
      }
      return lent
    },

    selected (item) {
     this.$emit('oga', false)
      this.$emit('open', item)
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
