<template>
  <div>
    <popupCharge :activation="charge" v-if="charge"/>
    <div v-if="!activeDetail && !activeInvoice && !activeArticle">
      <div
        class="flex items-center"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            @click="$emit('oga', false)"
            original
          />
          <div class="ml-4 w-3/5">
            Contrôle factures
          </div>
        </div>
      </div>

      <div
          v-if="charge && supplierData === null"
          class="flex justify-center mt-10"
      >
        <easy-spinner
            type="dots"
            size="70"
        />
      </div>

      <div class="mt-10 flex flex-wrap" v-if="supplierData !== null">
        <div class="w-1/2 pr-10" @click="selected">
          <bloc class="w-full" nom="Création facture" :total="supplierData.count"/>
        </div>

        <div class="w-1/2 pr-10" @click="activeInvoice = true; activeDetail = false">
          <bloc class="w-full" nom="Factures enregistrées" :total="invoice.length"/>
        </div>
      </div>
    </div>

    <div v-if="activeDetail">
      <liste :donnes="supplierData.data" :supplier="donnes" @oga="getData"/>
    </div>

    <div v-if="activeInvoice">
      <listeFacture :donne="invoice" :supplier="donnes" @oga="activeInvoice = false" @open="retourArticle"/>
    </div>

    <div v-if="activeArticle">
      <listeArticle :donne="selectedItem.articles" :supplier="donnes" @oga="activeArticle = false; activeInvoice = true"/>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import bloc from './bloc'
import liste from './liste'
import listeFacture from '../reception/factures'
import listeArticle from '../reception/liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import popupCharge from '../../popup/popupCharge'

export default {
  name: "Index",

  components: {
    bloc,
    liste,
    listeFacture,
    listeArticle,
    popupCharge
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
      },
      activeDetail: false,
      supplierData: [],
      charge: true,
      invoice: [],
      activeInvoice: false,
      activeArticle: false,
      selectedItem: null
    }
  },

  created () {
    this.getData()
    this.getInvoice()
  },

  methods: {
    selected () {
      this.activeDetail = true
    },

    getData () {
      this.activeDetail = false
      this.activeInvoice = false
      http.get(apiroutes.baseURL + apiroutes.allExport + '?id=' + this.donnes.id)
          .then(response => {
            console.log(response)
            this.supplierData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    getInvoice () {
      http.get(apiroutes.baseURL + apiroutes.supplierInvoice + '?id=' + this.donnes.id)
          .then(response => {
            console.log('invoicccccc')
            console.log(response)
            this.invoice = response
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourArticle (answer) {
      this.activeArticle = true
      this.selectedItem = answer
    },

    retourListe () {
      this.activeDetail = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
